<template>
  <div>
    <CModal color="warning" :show="show">
      <template v-slot:header>
        <h5 class="modal-title font-weight-bold">
          <CIcon name="BarCode" height="30" /> Barcodes
        </h5>
        <button
          type="button"
          aria-label="Close"
          class="close text-white"
          @click="closeModal()"
        >
          ×
        </button>
      </template>
      <div v-if="data">
        <h4 class="text-center mt-3">{{ data.name }}</h4>
        <p class="text-center mt-3 text-muted">{{ data.product_code }}</p>
        <div class="table-responsive table-wrapper">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th scope="col" class="text-center">Barcodes</th>
              </tr>
            </thead>
            <tbody v-if="data.barcodes">
              <tr v-for="code in data.barcodes.purchasing_codes" :key="code">
                <td class="text-center">
                  {{ code }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <template v-slot:footer>
        <CButton color="warning" @click="closeModal">Close</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  name: "StockBarcodeModal",
  props: ["data", "show"],
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
